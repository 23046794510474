import $i18n from 'panda-i18n';
import React, { useContext } from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { UploadStateContext } from '../context';
export const UploadButton = () => {
    const { props } = useContext(UploadStateContext);
    const { buttonProps, buttonText, disabled } = props;
    return (React.createElement(CnButton, { ...buttonProps, iconSize: "medium", disabled: disabled, icons: { loading: React.createElement(CnIcon, { type: "upload", size: "medium" }) } }, buttonText || $i18n.get({ id: 'Upload', dm: '上传', ns: 'CnUpload' })));
};
