import React, { useImperativeHandle, useContext, useRef, useState, } from 'react';
import classNames from 'classnames';
import defaultRequest from './request';
import attrAccept from './attr-accept';
import traverseFileTree from './traverse-file-tree';
import { UploadStateContext } from '../context';
import { fileToObject } from '../utils';
export const AjaxUploader = React.forwardRef(({ children }, ref) => {
    const { requestOSSDataService, onChange, onError, fileValidator, props } = useContext(UploadStateContext);
    const [fileKey, setFileKey] = useState();
    const { beforeUpload, disabled, multiple, accept, directory, openFileDialogOnClick, name, headers, ossUploadConfig, } = props;
    const reqListRef = useRef({});
    const fileInputRef = useRef(null);
    const onClick = () => {
        const el = fileInputRef.current;
        if (!el)
            return;
        if (children && children.type === 'button') {
            const parent = el.parentNode;
            parent.focus();
            parent.querySelector('button').blur();
        }
        el.click();
    };
    /**
     * Process file before upload. When all the file is ready, we start upload.
     */
    const processFile = async (file, fileList) => {
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        let transformedFile = file;
        try {
            fileValidator(file);
            if (beforeUpload) {
                transformedFile = await beforeUpload(file, fileList);
            }
        }
        catch (e) {
            props.onError(e, null, file);
            // Rejection will also trade as false
            transformedFile = false;
        }
        if (transformedFile === false)
            return null;
        const parsedData = 
        // string type is from legacy `transformFile`.
        // Not sure if this will work since no related test case works with it
        (typeof transformedFile === 'object' ||
            typeof transformedFile === 'string') &&
            transformedFile
            ? transformedFile
            : file;
        let parsedFile;
        if (parsedData instanceof File) {
            parsedFile = parsedData;
        }
        else {
            parsedFile = new File([parsedData], file.name, { type: file.type });
        }
        return parsedFile;
    };
    const post = async (file, ossData) => {
        // oss 上传凭证数据
        try {
            const { host, downloadUrl, url, key, ...data } = ossData;
            setFileKey(key);
            const fileObject = fileToObject(file, {
                status: 'idle',
                percent: 0,
                url,
                downloadUrl,
                key,
            });
            const requestOption = {
                action: host,
                filename: name,
                data: { ...data, key, success_action_status: 200 },
                file,
                headers,
                withCredentials: (ossUploadConfig === null || ossUploadConfig === void 0 ? void 0 : ossUploadConfig.withCredentials) || false,
                onProgress: ({ percent }) => {
                    onChange([{ ...fileObject, status: 'uploading', percent }]);
                },
                onSuccess: () => {
                    onChange([{ ...fileObject, status: 'done', percent: 100 }]);
                    delete reqListRef.current[key];
                },
                onError: (err, ret) => {
                    onError === null || onError === void 0 ? void 0 : onError(err, ret, fileObject);
                    delete reqListRef.current[key];
                },
            };
            reqListRef.current[key] = defaultRequest(requestOption);
        }
        catch (e) {
            props.onError(e, null, file);
        }
    };
    const uploadFiles = async (files) => {
        const postFiles = files.map((file) => {
            return processFile(file, files);
        });
        let fileList = await Promise.all(postFiles);
        fileList = fileList.filter(Boolean);
        const ossDataList = await Promise.all(fileList.map((item) => {
            return requestOSSDataService({ fileName: item.name });
        }));
        const list = ossDataList.map((item, fileIndex) => {
            return fileToObject(fileList[fileIndex], {
                key: item.key,
                status: 'idle',
                percent: 0,
            });
        });
        onChange(list);
        ossDataList.forEach((ossData, fileIndex) => {
            post(fileList[fileIndex], ossData);
        });
    };
    useImperativeHandle(ref, () => ({
        uploader: {
            uploadFiles,
        },
    }));
    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onClick();
        }
    };
    const onFileDrop = (e) => {
        e.preventDefault();
        if (e.type === 'dragover') {
            return;
        }
        if (directory) {
            traverseFileTree(Array.prototype.slice.call(e.dataTransfer.items), uploadFiles, (_file) => attrAccept(_file, accept));
        }
        else {
            let files = [...e.dataTransfer.files].filter((file) => {
                return attrAccept(file, accept);
            });
            if (multiple === false) {
                files = files.slice(0, 1);
            }
            uploadFiles(files);
        }
    };
    // because input don't have directory/webkitdirectory type declaration
    const dirProps = directory
        ? { directory: 'directory', webkitdirectory: 'webkitdirectory' }
        : {};
    const events = disabled
        ? {}
        : {
            onClick: openFileDialogOnClick ? onClick : () => { },
            onKeyDown: openFileDialogOnClick ? onKeyDown : () => { },
            onDrop: onFileDrop,
            onDragOver: onFileDrop,
        };
    return (React.createElement("span", { ...events, className: classNames('rc-upload', { 'rc-upload-disabled': disabled }), role: "button" },
        React.createElement("input", { key: fileKey, disabled: disabled, type: "file", ref: fileInputRef, onClick: (e) => e.stopPropagation(), style: { display: 'none' }, accept: accept, ...dirProps, multiple: multiple, onChange: (e) => {
                const { files } = e.target;
                const acceptedFiles = [...files].filter((file) => !directory || attrAccept(file, accept));
                uploadFiles(acceptedFiles);
            } }),
        children));
});
