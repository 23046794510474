import React, { useContext } from 'react';
import classNames from 'classnames';
import { UploadStateContext } from './context';
import { AjaxUploader } from './rc-upload/ajax-uploader';
import { UploadList } from './upload-list';
import { UploadDropZone } from './upload-drop-zone';
export const OSSUploadDragger = React.forwardRef((_, ref) => {
    const { props } = useContext(UploadStateContext);
    const { style, className, readOnly } = props;
    return (React.createElement("div", { "data-name": "CnDraggerUpload", style: style, className: classNames(className, 'cn-ui-upload', 'cn-ui-upload-dragger', {
            'cn-ui-upload-readonly': readOnly,
        }) },
        readOnly ? null : (React.createElement(AjaxUploader, { ref: ref },
            React.createElement(UploadDropZone, null))),
        React.createElement(UploadList, null)));
});
