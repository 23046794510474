import React, { useContext } from 'react';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnBox } from '@/components/cn-box';
import { calcSize, canUseImageViewer, getFileName } from './utils';
import { StaticFileIcon } from './icons';
import { CnIcon } from '@/components/cn-icon';
import { CnLoading } from '@/components/cn-loading';
import { CnProgress } from '@/components/cn-progress';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { useShowPreview } from './hooks/useShowPreview';
import { useShowDownload } from './hooks/useShowDownload';
import { useShowRemove } from './hooks/useShowRemove';
import { preview } from './preview';
import { UploadStateContext } from '../context';
export const UploadListItem = ({ file, }) => {
    const { cacheFilesRef, onRemove, props } = useContext(UploadStateContext);
    const { disabled, readOnly, webOfficeEnable, webOfficeEditRequestConfig, webOfficePreviewRequestConfig, } = props;
    const { size, key } = file;
    const cacheFile = cacheFilesRef.current[key];
    const isUploading = (cacheFile === null || cacheFile === void 0 ? void 0 : cacheFile.status) && ['idle', 'uploading'].includes(cacheFile.status);
    const renderFileFooter = () => {
        if (isUploading) {
            return React.createElement(CnProgress, { percent: (cacheFile === null || cacheFile === void 0 ? void 0 : cacheFile.percent) || 0 });
        }
        if (size)
            return calcSize(size);
        return null;
    };
    const showRemove = useShowRemove({
        showRemove: !disabled && !readOnly && props.showRemove,
        file,
    });
    const showDownload = useShowDownload({
        showDownload: props.showDownload,
        onDownload: props.onDownload,
        file,
    });
    const onDownload = () => {
        if (props.onDownload)
            props.onDownload(file);
        else
            downloadFile(file.url, file.name);
    };
    const showPreview = useShowPreview({
        showPreview: props.showPreview,
        onPreview: props.onPreview,
        file,
    });
    const defaultOnPreview = async () => {
        if (props.onPreview)
            props.onPreview(file);
        else if (canUseImageViewer(file))
            CnImageViewer.open({ src: file.url });
        else
            window.open(file.url);
    };
    const onPreview = async () => {
        if (!showPreview)
            return;
        if (webOfficeEnable) {
            /** 内置支持图片预览，无需调用 web office 预览能力 */
            if (canUseImageViewer(file))
                CnImageViewer.open({ src: file.url });
            try {
                await preview({
                    requestConfig: readOnly
                        ? webOfficePreviewRequestConfig
                        : webOfficeEditRequestConfig,
                    key,
                });
            }
            catch (err) {
                defaultOnPreview();
            }
            return;
        }
        defaultOnPreview();
    };
    return (React.createElement("div", { className: "cn-ui-upload-list-item", onClick: onPreview },
        React.createElement("div", { className: "cn-ui-upload-list-item-header" }, isUploading ? (React.createElement(CnLoading, { className: "cn-ui-upload-list-loading", size: "small" })) : (React.createElement(StaticFileIcon, { file: file }))),
        React.createElement("div", { className: "cn-ui-upload-list-item-info" },
            React.createElement(CnEllipsis, { className: "cn-ui-upload-list-item-info-file-name", ellipsisPosition: "middle", endCharCount: 4 }, getFileName(file)),
            React.createElement("div", { className: "cn-ui-upload-list-item-info-footer" }, renderFileFooter())),
        React.createElement(CnBox, { direction: "row", spacing: 4, align: "center", className: "cn-ui-upload-list-item-actions" },
            showPreview ? (React.createElement("div", { onClick: (event) => {
                    event.stopPropagation();
                    onPreview();
                }, className: "cn-ui-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "eye", size: 14 }) }, webOfficeEnable
                    ? '点击最长可预览/编辑30分钟！过期后如需继续操作，请返回页面重新点击该按钮！'
                    : '预览'))) : null,
            showDownload ? (React.createElement("div", { onClick: (event) => {
                    event.stopPropagation();
                    onDownload();
                }, className: "cn-ui-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "download", size: 14 }) }, "\u4E0B\u8F7D"))) : null,
            showRemove ? (React.createElement("div", { onClick: (event) => {
                    event.stopPropagation();
                    onRemove === null || onRemove === void 0 ? void 0 : onRemove(file);
                }, className: "cn-ui-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "close", size: 14 }) }, "\u5220\u9664"))) : null)));
};
