import { pluginManager } from '@/components/cn-utils/plugin';
// CnFormat.registerFormat('CnArrayView', (props: CnFormatProps) => React.ReactNode)
export const registerFormat = (name, render) => {
    pluginManager.registerPlugin([
        {
            componentName: 'CnFormat',
            plugins: [
                {
                    name: `CnFormatPlugin-${name}`,
                    sourceCode: (props, context) => {
                        const { format } = props;
                        if ((format === null || format === void 0 ? void 0 : format.toLowerCase()) === name.toLowerCase() && !(props === null || props === void 0 ? void 0 : props.cell)) {
                            props.cell = render;
                        }
                        return { props, context };
                    },
                    lowCode: {
                        getPrototypeList: () => { },
                    },
                },
            ],
        },
    ]);
};
export const getAllSupportFormat = () => {
    const allGlobalPlugins = pluginManager.getPluginsByComponentName('CnFormat');
    const allSupportFormat = [];
    allGlobalPlugins.forEach((plugin) => {
        const { name } = plugin;
        allSupportFormat.push(name.replace('CnFormatPlugin-', '').toLowerCase());
    });
    return allSupportFormat;
};
