import * as React from 'react';
import { CnLoading } from '@/components/cn-loading';
// 定义一个函数 handleLoading，用于处理加载状态
export function handleLoading(loading, data) {
    // 定义一个组件 ContentLoading，用于显示加载中的状态
    const ContentLoading = () => {
        return (
        // 返回一个 div，内部包含一个 CnLoading 组件，用于显示加载动画
        React.createElement("div", { className: "content-loading" },
            React.createElement(CnLoading, { size: "medium" })));
    };
    // 定义一个变量 IsContentLoading，当数据长度为 0 且处于加载状态时，该变量为真
    const IsContentLoading = (data === null || data === void 0 ? void 0 : data.length) === 0 && loading;
    // 如果 IsContentLoading 为真，则返回一个对象，其中 notFoundContent 属性为 ContentLoading 组件
    if (IsContentLoading) {
        return {
            notFoundContent: React.createElement(ContentLoading, null),
        };
    }
    // 如果处于加载状态，则返回一个对象，其中 state 属性为 'loading'
    if (loading) {
        return {
            state: 'loading',
        };
    }
    // 如果不处于加载状态，则返回一个对象，其中 state 属性为 undefined
    return {
        state: undefined,
    };
}
