import React, { createContext, useEffect, useRef } from 'react';
import { fileValidator } from './utils';
import { useControlled } from './hooks/use-controlled';
export const UploadStateContext = createContext({});
export const UploadContext = (props) => {
    const { disabled, limit, children, useDetailValue, defaultValue, requestOSSDataService, } = props;
    const cacheFilesRef = useRef({});
    const [value, setValue] = useControlled({
        value: props.value,
        onChange: props.onChange,
        defaultValue,
    });
    const valueRef = useRef(value);
    useEffect(() => {
        valueRef.current = value;
    }, [value]);
    /**
     * 只暴露必要的数据
     */
    const onChangeSimple = (_value) => {
        const _simpleValue = _value.map((item) => {
            return {
                key: item.key,
                name: item.name,
                size: item.size,
                url: item.url,
            };
        });
        setValue(_simpleValue);
    };
    const onChange = (fileList) => {
        const newValueList = fileList.reduce((result, file) => {
            const newValue = [...result];
            const { key } = file;
            cacheFilesRef.current[key] = file;
            const fileIndex = result.findIndex((_val) => _val.key === key);
            if (fileIndex >= 0) {
                newValue[fileIndex] = file;
            }
            else {
                newValue.push(file);
            }
            return newValue;
        }, valueRef.current || []);
        if (useDetailValue) {
            setValue(newValueList);
        }
        else {
            onChangeSimple(newValueList);
        }
    };
    const onError = (error, response, file) => {
        const { key } = file;
        props.onError(error, response, file.originFile);
        setValue(valueRef.current.filter((_val) => _val.key !== key));
    };
    const onRemove = (file) => {
        const { key } = file;
        if (props.onRemove) {
            props.onRemove(file);
        }
        else {
            setValue(valueRef.current.filter((_val) => _val.key !== key));
        }
    };
    return (React.createElement(UploadStateContext.Provider, { value: {
            props,
            value,
            onChange,
            onRemove,
            onError,
            fileValidator,
            requestOSSDataService,
            cacheFilesRef,
            disableUpload: disabled || (value === null || value === void 0 ? void 0 : value.length) >= limit,
        } }, children));
};
