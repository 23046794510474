import './index.scss';
import React from 'react';
import locale from '@/locales';
import { withI18n } from 'panda-i18n';
import { CnOSSUploadView } from './oss-upload';
import { CnOSSUpload as CnOSSUploadNew, } from '../cn-oss-upload-v2';
const CnOSSUploadOld = withI18n(CnOSSUploadView, { locale });
export const CnOSSUpload = React.forwardRef((props, ref) => {
    if (props.version === 'v2') {
        return React.createElement(CnOSSUploadNew, { ...props, ref: ref });
    }
    return React.createElement(CnOSSUploadOld, { ...props, ref: ref });
});
