import $i18n from 'panda-i18n';
import cx from 'classnames';
import React from 'react';
import { ExpansionCell, icons, InlineFlexCell } from '../../dep';
import { treeMetaSymbol, isSysFixedCol } from '../../global';
import { mergeCellProps, safeRender, safeGetCellProps, safeRenderHeader, renderPre, } from '../../utils';
import isLeafNodeStandard from './is-leaf-node-standard';
export function treeModePipeline(opts = {}) {
    return function treeModeStep(pipeline) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const stateKey = 'treeMode';
        const ctx = pipeline.ctx;
        // 获取 primaryKey
        const primaryKey = pipeline.ensurePrimaryKey('treeMode');
        if (typeof primaryKey !== 'string') {
            throw new Error($i18n.get({
                id: 'TreeModeOnlySupportsStringsAsPrimaryKey_49364134',
                dm: 'treeMode 仅支持字符串作为 primaryKey',
                ns: 'CnBaseTable',
            }));
        }
        // 获取展开的 keys
        const openKeys = (_c = (_b = (_a = opts.openKeys) !== null && _a !== void 0 ? _a : pipeline.getStateAtKey(stateKey)) !== null && _b !== void 0 ? _b : opts.defaultOpenKeys) !== null && _c !== void 0 ? _c : [];
        // 获取去重的 openKeys
        const openKeySet = new Set(openKeys);
        const onChangeOpenKeys = (nextKeys, key, action, row) => {
            var _a;
            (_a = opts.onChangeOpenKeys) === null || _a === void 0 ? void 0 : _a.call(opts, nextKeys, key, action, row);
            pipeline.setStateAtKey(stateKey, nextKeys, { key, action });
        };
        const keyRowMap = {};
        const toggle = (rowKey) => {
            const expanded = openKeySet.has(rowKey);
            if (expanded) {
                onChangeOpenKeys(openKeys.filter((key) => key !== rowKey), rowKey, 'collapse', keyRowMap[rowKey]);
            }
            else {
                onChangeOpenKeys([...openKeys, rowKey], rowKey, 'expand', keyRowMap[rowKey]);
            }
        };
        const isLeafNode = (_d = opts.isLeafNode) !== null && _d !== void 0 ? _d : isLeafNodeStandard;
        const clickArea = (_e = opts.clickArea) !== null && _e !== void 0 ? _e : 'cell';
        const treeMetaKey = (_f = opts.treeMetaKey) !== null && _f !== void 0 ? _f : treeMetaSymbol;
        const stopClickEventPropagation = Boolean(opts.stopClickEventPropagation);
        // indents
        const iconWidth = ctx.indents.iconWidth;
        const iconIndent = (_g = opts.iconIndent) !== null && _g !== void 0 ? _g : ctx.indents.iconIndent;
        const iconGap = (_h = opts.iconGap) !== null && _h !== void 0 ? _h : ctx.indents.iconGap;
        const indentSize = (_j = opts.indentSize) !== null && _j !== void 0 ? _j : ctx.indents.indentSize;
        return pipeline.mapDataSource(processDataSource).mapColumns(processColumns);
        function processDataSource(input) {
            const result = [];
            dfs(input, 0, '');
            function dfs(nodes, depth, parentPre = '') {
                if (nodes == null) {
                    return;
                }
                for (const node of nodes) {
                    const rowKey = node[primaryKey];
                    keyRowMap[rowKey] = node;
                    const expanded = openKeySet.has(rowKey);
                    const isLeaf = isLeafNode(node, { depth, expanded, rowKey });
                    const treeMeta = { depth, isLeaf, expanded, rowKey };
                    result.push({
                        [treeMetaKey]: treeMeta,
                        ...node,
                        [renderPre]: `${parentPre}-tree_level_${depth}`,
                    });
                    if (!isLeaf && expanded) {
                        dfs(node.children, depth + 1, `${parentPre}-tree_level_${depth}${rowKey}`);
                    }
                }
            }
            return result;
        }
        function processColumns(columns) {
            if (columns.length === 0) {
                return columns;
            }
            const sycFixedCols = [];
            const restCols = [];
            columns.forEach((col) => {
                if (isSysFixedCol(col.symbol)) {
                    sycFixedCols.push(col);
                }
                else {
                    restCols.push(col);
                }
            });
            if (restCols.length === 0) {
                return restCols;
            }
            const [firstCol, ...others] = restCols;
            const render = (value, record, recordIndex) => {
                const content = safeRender(firstCol, record, recordIndex);
                if (record[treeMetaKey] == null) {
                    // 没有 treeMeta 信息的话，就返回原先的渲染结果
                    return content;
                }
                const { rowKey, depth, isLeaf, expanded } = record[treeMetaKey];
                const indent = iconIndent + depth * indentSize;
                if (isLeaf) {
                    // if (depth !== 0) {
                    //   return content;
                    // }
                    return (React.createElement(InlineFlexCell, { className: "expansion-cell leaf" },
                        React.createElement("span", { style: { marginLeft: indent + iconWidth + iconGap } }, content)));
                }
                const onClick = (e) => {
                    if (stopClickEventPropagation) {
                        e.stopPropagation();
                    }
                    toggle(rowKey);
                };
                const expandCls = expanded ? 'expanded' : 'collapsed';
                return (React.createElement(ExpansionCell, { className: cx('expansion-cell', expandCls), style: {
                        cursor: clickArea === 'content' ? 'pointer' : undefined,
                    }, onClick: clickArea === 'content' ? onClick : undefined },
                    React.createElement(icons.CaretRight, { className: cx('expansion-icon', expandCls), style: {
                            cursor: clickArea === 'icon' ? 'pointer' : undefined,
                            marginLeft: indent,
                            marginRight: iconGap,
                        }, onClick: clickArea === 'icon' ? onClick : undefined }),
                    content));
            };
            const getCellProps = (value, record, rowIndex) => {
                const prevProps = safeGetCellProps(firstCol, record, rowIndex);
                if (record[treeMetaKey] == null) {
                    // 没有 treeMeta 信息的话，就返回原先的 cellProps
                    return prevProps;
                }
                const { isLeaf, rowKey } = record[treeMetaKey];
                if (isLeaf) {
                    return prevProps;
                }
                return mergeCellProps(prevProps, {
                    onClick(e) {
                        if (stopClickEventPropagation) {
                            e.stopPropagation();
                        }
                        toggle(rowKey);
                    },
                    style: { cursor: 'pointer' },
                });
            };
            return [
                ...sycFixedCols,
                {
                    ...firstCol,
                    title: (React.createElement("span", { style: {
                            paddingLeft: iconIndent + iconWidth + iconGap,
                            display: 'flex',
                            alignItems: 'center',
                        } }, safeRenderHeader(firstCol))),
                    render,
                    getCellProps: clickArea === 'cell' ? getCellProps : firstCol.getCellProps,
                },
                ...others,
            ];
        }
    };
}
