import * as React from 'react';
import { registerFormat } from '../register';
import './cn-input-text-area.scss';
registerFormat('CnInputTextArea', (value) => {
    if (!value) {
        return null;
    }
    return (React.createElement("div", null, value.split('\n').map((data) => {
        return React.createElement("p", { className: "cn-format-cn-input-text-area" }, data);
    })));
});
