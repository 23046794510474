import { useState } from 'react';
export const useControlled = (param) => {
    const { value, onChange, defaultValue } = param;
    const defaultOnChange = () => {
        console.warn('[groot-useControlled] 已传入value，但并未检查到onChange，缺省处理。');
    };
    const isControlled = value !== undefined;
    const [innerValue, setInnerValue] = useState(defaultValue);
    const _value = isControlled ? value : innerValue;
    const hasOnChange = onChange && typeof onChange === 'function';
    // eslint-disable-next-line no-nested-ternary
    const _onChange = isControlled
        ? hasOnChange
            ? onChange
            : defaultOnChange
        : hasOnChange
            ? (v) => {
                onChange(v);
                setInnerValue(v);
            }
            : setInnerValue;
    return [_value, _onChange];
};
