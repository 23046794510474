import dayjs from 'dayjs';
import moment from 'moment';
import isFunction from 'lodash/isFunction';
import { handlePropRules } from './format';
import { formatValue } from './helper';
const formatConfig = {
    moment: {
        componentNames: [
            'DatePicker',
            'RangePicker',
            'WeekPicker',
            'MonthPicker',
            'YearPicker',
            'TimePicker',
        ],
        getValueFormatter: (val) => formatValue(val, (v) => (moment.isMoment(v) ? v.valueOf() : v)),
        setValueFormatter: (val) => formatValue(val, (v) => (typeof v === 'number' ? moment(v) : v)),
    },
    dayjs: {
        componentNames: [
            'DatePicker2',
            'RangePicker2',
            'WeekPicker2',
            'MonthPicker2',
            'QuarterPicker2',
            'YearPicker2',
            'TimePicker2',
        ],
        getValueFormatter: (val) => formatValue(val, (v) => (dayjs.isDayjs(v) ? v.valueOf() : v)),
        setValueFormatter: (val) => formatValue(val, (v) => (typeof v === 'number' ? dayjs(v) : v)),
    },
};
export const getValueFormatterInjectProps = (componentName) => {
    const typeName = Object.keys(formatConfig).find((key) => {
        var _a;
        return (_a = formatConfig[key]) === null || _a === void 0 ? void 0 : _a.componentNames.includes(componentName);
    });
    return typeName
        ? {
            _formattertypename: typeName,
        }
        : null;
};
export const injectFieldInit = (field, props) => {
    if (!field)
        return;
    if (field.init._inject)
        return;
    const fieldInit = field.init;
    field.init = ((name, fieldOption, rprops) => {
        var _a, _b;
        let option = fieldOption;
        const typeName = (_a = fieldOption === null || fieldOption === void 0 ? void 0 : fieldOption.props) === null || _a === void 0 ? void 0 : _a._formattertypename;
        const rules = (_b = fieldOption === null || fieldOption === void 0 ? void 0 : fieldOption.props) === null || _b === void 0 ? void 0 : _b.rules;
        if (typeName) {
            const { getValueFormatter, setValueFormatter } = formatConfig[typeName];
            option = {
                getValueFormatter,
                setValueFormatter,
                ...fieldOption,
            };
        }
        if (rules) {
            option = {
                ...fieldOption,
                rules: (Array.isArray(fieldOption.rules) ? fieldOption.rules : [fieldOption.rules]).concat(handlePropRules(rules)),
            };
        }
        if (isFunction(props === null || props === void 0 ? void 0 : props.coverItemId)) {
            option = {
                ...option,
                id: props.coverItemId(name),
            };
        }
        return fieldInit.apply(field, [name, option, rprops]);
    });
    field.init._inject = true;
};
