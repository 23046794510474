import './index.scss';
import * as React from 'react';
import cx from 'classnames';
import { withNativeProps } from '@cainiaofe/cn-ui-utils';
import { CnTextEllipsis } from '../cn-ellipsis';
export const CnStatusTag = (props) => {
    const { type, status, size } = props;
    const children = props.children || props.text;
    if (!children)
        return null;
    return withNativeProps(props, React.createElement("div", { className: cx('cn-ui-status-tag', {
            [`cn-ui-status-tag-type-${type}`]: Boolean(type),
            [`cn-ui-status-tag-size-${size}`]: Boolean(size),
            [`cn-ui-status-tag-status-${status}`]: Boolean(status),
        }) },
        React.createElement(CnTextEllipsis, { line: 1 }, children)));
};
CnStatusTag.displayName = 'CnStatusTag';
CnStatusTag.defaultProps = {
    type: 'normal',
    size: 'medium',
    status: 'info',
};
