import './index.scss';
import React, { useContext } from 'react';
import cx from 'classnames';
import { Overlay } from '@fusion/lib';
import { UploadListItem } from '../upload-list-item';
import { StaticFileIcon } from '../upload-list-item/icons';
import { CnTag } from '@/components/cn-tag';
import { UploadStateContext } from '../context';
export function MiniUploadList({ popupProps, }) {
    const { value, props } = useContext(UploadStateContext);
    const { showUploadList } = props;
    if (!showUploadList)
        return null;
    if (!Array.isArray(value))
        return null;
    if (!(value === null || value === void 0 ? void 0 : value.length))
        return null;
    return (React.createElement(Overlay.Popup, { trigger: React.createElement("div", { className: "cn-ui-upload-list-table-trigger" },
            value.slice(0, 3).map((item) => (React.createElement(StaticFileIcon, { key: item.key || item.name || item.url, file: item, className: "table-inline-icon" }))),
            value.length > 3 ? (React.createElement(CnTag, { key: "item-tag", size: "small", type: "primary", className: "table-inline-more" },
                value.length - 3,
                " +")) : null), className: "cn-ui-upload-list-table-popup", ...popupProps },
        React.createElement("div", { className: cx('cn-ui-upload-list', 'cn-ui-upload-list-mini') }, value.map((item) => (React.createElement(UploadListItem, { key: item.key, file: item }))))));
}
