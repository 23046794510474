import * as React from 'react';
import cx from 'classnames';
import isFunction from 'lodash/isFunction';
import { AnchorList } from './anchor-list';
import { findNode, findAllNodes, getNestHeadings, startListen, removeListen, jumpToNode, } from './utils';
export const CnAnchor = React.forwardRef((props, ref) => {
    const { offsetY, onChange, className, style, contentSelector, headingSelector, ignoreSelector, levelSelector, showRoot, } = props;
    const [dataSource, setDataSource] = React.useState([]);
    const [currentValue, setCurrentValue] = React.useState('');
    const nodeRef = React.useRef({});
    const bodyRef = React.useRef();
    const getDefaultHeadingsContainer = () => {
        if (!bodyRef.current)
            return window;
        let container = bodyRef.current.closest('.cn-ui-drawer-content');
        if (container)
            return container;
        container = bodyRef.current.closest('.cn-next-dialog-body');
        if (container)
            return container;
        return bodyRef.current.closest('#cn-ui-page-scroll-container') || window;
    };
    React.useEffect(() => {
        // 获取所有 headings 项
        let headingsContainer = findNode(contentSelector);
        if (!headingsContainer) {
            headingsContainer = getDefaultHeadingsContainer();
        }
        const headings = findAllNodes(headingSelector, headingsContainer, ignoreSelector);
        const levelNodes = findAllNodes(levelSelector, headingsContainer);
        const nestedHeadings = getNestHeadings(headings, headingsContainer, levelNodes, levelSelector);
        nodeRef.current = {
            headingsContainer,
            headings,
            levelNodes,
            nestedHeadings,
        };
        setDataSource(nestedHeadings);
        startListen(nestedHeadings, headingsContainer, updateCurrentValue, {
            offsetY,
        });
        return () => {
            removeListen(nestedHeadings, headingsContainer);
        };
    }, [contentSelector, headingSelector, ignoreSelector, levelSelector]);
    const updateCurrentValue = (newValue) => {
        setCurrentValue(newValue);
    };
    const handleChange = (newValue) => {
        var _a;
        if (!nodeRef.current)
            return;
        const { htmlId } = newValue;
        setCurrentValue(htmlId);
        removeListen(nodeRef.current.nestedHeadings, nodeRef.current.headingsContainer);
        jumpToNode(htmlId, (_a = nodeRef.current) === null || _a === void 0 ? void 0 : _a.headingsContainer, offsetY);
        startListen(nodeRef.current.nestedHeadings, nodeRef.current.headingsContainer, updateCurrentValue, {
            offsetY,
        });
        if (isFunction(onChange)) {
            onChange(newValue);
        }
    };
    const classes = cx('cn-ui-anchor', 'cn-ui-anchor-expand', {
        [className]: !!className,
    });
    return (React.createElement("div", { className: classes, style: {
            top: 120,
            right: 40,
            ...style,
        }, ref: ref },
        React.createElement("div", { className: "cn-ui-anchor-body", ref: bodyRef },
            React.createElement(AnchorList, { dataSource: dataSource, value: currentValue, onSelect: handleChange, showRoot: showRoot }))));
});
CnAnchor.displayName = 'CnAnchor';
CnAnchor.defaultProps = {
    headingSelector: '.cn-ui-form-card-title',
    levelSelector: '.cn-ui-card, .cn-ui-card-sub-card, .cn-ui-card-sub-area-card',
    offsetY: 0,
    showRoot: true,
};
