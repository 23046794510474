function getError(option, xhr) {
    const msg = `cannot post ${option.action} ${xhr.status}'`;
    const err = new Error(msg);
    err.status = xhr.status;
    err.url = option.action;
    return err;
}
function getBody(xhr) {
    const text = xhr.responseText || xhr.response;
    if (!text)
        return text;
    try {
        return JSON.parse(text);
    }
    catch (e) {
        return text;
    }
}
export default function upload(option) {
    // eslint-disable-next-line no-undef
    const xhr = new XMLHttpRequest();
    if (option.onProgress && xhr.upload) {
        xhr.upload.onprogress = function progress(e) {
            if (e.total > 0) {
                e.percent = (e.loaded / e.total) * 100;
            }
            option.onProgress(e);
        };
    }
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    if (option.data) {
        Object.keys(option.data).forEach((key) => {
            const value = option.data[key];
            // support key-value array data
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    // { list: [ 11, 22 ] }
                    // formData.append('list[]', 11);
                    formData.append(`${key}[]`, item);
                });
                return;
            }
            formData.append(key, value);
        });
    }
    // eslint-disable-next-line no-undef
    if (option.file instanceof Blob) {
        formData.append(option.filename, option.file, option.file.name);
    }
    else {
        formData.append(option.filename, option.file);
    }
    xhr.onerror = function error(e) {
        option.onError(e);
    };
    xhr.onload = function onload() {
        // allow success when 2xx status
        // see https://github.com/react-component/upload/issues/34
        if (xhr.status < 200 || xhr.status >= 300) {
            return option.onError(getError(option, xhr), getBody(xhr));
        }
        return option.onSuccess(getBody(xhr), xhr);
    };
    xhr.open('post', option.action, true);
    // Has to be after `.open()`. See https://github.com/enyo/dropzone/issues/179
    if (option.withCredentials && 'withCredentials' in xhr) {
        xhr.withCredentials = true;
    }
    const headers = option.headers || {};
    Object.keys(headers).forEach((h) => {
        if (headers[h] !== null) {
            xhr.setRequestHeader(h, headers[h]);
        }
    });
    xhr.send(formData);
    return {
        abort() {
            xhr.abort();
        },
    };
}
