import $i18n from 'panda-i18n';
import React, { useEffect } from 'react';
import { getRequestService, getErrorMsg, } from '@cainiaofe/cn-ui-utils';
import { CnMessage } from '@/components/cn-message';
import { defaultRequestConfig } from './constant';
import { loadWebOfficeSDK } from '../cn-upload/utils/load-web-office-sdk';
import { UploadContext } from './context';
import { OSSUploadNormal } from './oss-upload-normal';
import { OSSUploadDragger } from './oss-upload-dragger';
import { OSSUploadMini } from './oss-upload-mini';
/**
 * 兼容 uploadProps 属性，未来会被废弃，直接使用props
 * @deprecated
 */
const doMergeProps = (props) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { uploadProps = {}, ...restProps } = props;
    return { ...uploadProps, ...restProps };
};
export const OSSUpload = React.forwardRef((props, ref) => {
    const { shape, ...restProps } = doMergeProps(props);
    useEffect(() => {
        if (props.webOfficeEnable)
            loadWebOfficeSDK();
    }, [props.webOfficeEnable]);
    const requestOSSDataService = (params) => {
        const getRequestConfig = () => {
            // 标准逻辑
            if (props.requestConfig)
                return props.requestConfig;
            // start 兼容逻辑
            const service = restProps.service;
            if (service) {
                // 自定义函数
                if (typeof service === 'function') {
                    return { service };
                }
                // requestConfig 配置
                return service;
            }
            // end 兼容逻辑
            return defaultRequestConfig;
        };
        return getRequestService(getRequestConfig())(params);
    };
    /**
     * 组件内部主动通过 CnMessage.error 暴露错误内容
     */
    const onError = (error, response, file) => {
        if (restProps.onError)
            restProps.onError(error, response, file);
        const msg = getErrorMsg(error, $i18n.get({
            id: 'OSSCredentialAcquisitionFailed',
            dm: 'OSS凭证获取失败',
            ns: 'CnOssUpload',
        }));
        CnMessage.error({ content: msg });
    };
    return (React.createElement(UploadContext, { ...restProps, onError: onError, requestOSSDataService: requestOSSDataService },
        shape === 'dragger' ? React.createElement(OSSUploadDragger, { ref: ref }) : null,
        shape === 'normal' ? React.createElement(OSSUploadNormal, { ref: ref }) : null,
        shape === 'mini' ? React.createElement(OSSUploadMini, { ref: ref }) : null));
});
OSSUpload.defaultProps = {
    name: 'file',
    headers: {},
    limit: 999,
    shape: 'normal',
    multiple: false,
    readOnly: false,
    useDetailValue: true,
    showPreview: true,
    showRemove: true,
    showDownload: true,
    showUploadList: true,
    openFileDialogOnClick: true,
    webOfficeEnable: false,
    webOfficeEditRequestConfig: {
        url: '/api/tars2/file/upload/generateEditUrl',
    },
    webOfficePreviewRequestConfig: {
        url: '/api/tars2/file/upload/generatePreviewUrl',
    },
};
OSSUpload.displayName = 'CnOssUpload';
