import $i18n, { withI18n } from 'panda-i18n';
import * as React from 'react';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
// import numbro from 'numbro';
import NextNumberPicker from './number-picker';
import { CnMessage } from '@/components/cn-message';
import locale from '@/locales';
import './percent-input.scss';
class CnPercentInputOrigin extends React.Component {
    constructor(props) {
        super(props);
        let value;
        if ('value' in props) {
            value = props.value;
        }
        else {
            value = props.defaultValue;
        }
        this.state = {
            value: value === undefined || value === null ? undefined : value,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if ('value' in nextProps && nextProps.value !== prevState.value) {
            const { value } = nextProps;
            return {
                value: value === undefined || value === null ? '' : value,
            };
        }
        return null;
    }
    render() {
        const { className, readOnly, ...otherProps } = this.props;
        // 组件样式
        const classes = cx({
            'cn-ui-percent-input': true,
            [className]: !!className,
        });
        // 百分比符号样式
        const percentMarkClasses = cx({
            'cn-ui-percent-input-mark': true,
            'cn-ui-percent-input-readonly-mark': !!readOnly,
        });
        const innerAfter = React.createElement("span", { className: percentMarkClasses }, "%");
        if (readOnly) {
            const bigNumberValue = new BigNumber(this.state.value);
            let displayValue = bigNumberValue.toString();
            if (!bigNumberValue.isNaN()) {
                if (!isNaN(otherProps.precision)) {
                    displayValue = bigNumberValue.toFixed(Number(otherProps.precision));
                }
                if (otherProps.format) {
                    displayValue = otherProps.format(displayValue);
                }
            }
            else {
                displayValue = '-';
            }
            return (React.createElement("div", { className: classes },
                otherProps.innerBefore,
                otherProps.addonTextBefore,
                displayValue,
                otherProps.addonTextAfter,
                otherProps.innerAfter || innerAfter));
        }
        return (React.createElement(NextNumberPicker, { "data-name": "CnPercentInput", className: classes, innerAfter: innerAfter, prefix: "cn-next-", onCorrect: (obj) => {
                CnMessage.notice($i18n.get({
                    id: 'PleaseEnterTheCorrectNumber',
                    dm: '请输入正确的数字',
                    ns: 'CnNumberPicker',
                }));
            }, ...this.props }));
    }
}
CnPercentInputOrigin.displayName = 'CnPercentInput';
CnPercentInputOrigin.defaultProps = {
    // 百分比极少出现负数，默认 min=0
    min: 0,
};
export const CnPercentInput = withI18n(CnPercentInputOrigin, {
    componentName: 'CnPercentInput',
    locale,
    forwardRef: true,
});
