import * as React from 'react';
import { HocBaseComponents, Plugin, pluginManager, } from '@/components/cn-utils/plugin';
import { registerFormat, getAllSupportFormat } from './register';
const CnFormatFallback = (props) => {
    const { cell } = props;
    if (cell) {
        return cell(props.value);
    }
    return null;
};
export const CnFormat = (props) => {
    const plugin = React.useMemo(() => {
        const pluginInstance = new Plugin('CnFormat', pluginManager);
        pluginInstance.setBasicsPlugin([
            (props, context) => {
                return {
                    props,
                    context: {
                        ...context,
                    },
                };
            },
        ]);
        pluginInstance.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        pluginInstance.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnFormat')
            .map((item) => item.method));
        return pluginInstance;
    }, []);
    // 获取到的全量插件
    const plugins = plugin.getPlugin();
    // 传入下层
    return HocBaseComponents(CnFormatFallback, { props, plugins });
};
CnFormat.defaultProps = {
    type: 'CnInput',
};
CnFormat.displayName = 'CnFormat';
CnFormat.registerFormat = registerFormat;
CnFormat.getAllSupportFormat = getAllSupportFormat;
