import React, { useContext } from 'react';
import classNames from 'classnames';
import { CnBox } from '@/components/cn-box';
import { UploadButton } from './upload-button';
import { UploadStateContext } from './context';
import { AjaxUploader } from './rc-upload/ajax-uploader';
import { UploadList } from './upload-list';
export const OSSUploadNormal = React.forwardRef((_, ref) => {
    const { props, disableUpload } = useContext(UploadStateContext);
    const { style, className, readOnly, description } = props;
    return (React.createElement("div", { "data-name": "CnUpload", style: style, className: classNames(className, 'cn-ui-upload', {
            'cn-ui-upload-readonly': readOnly,
            'cn-ui-upload-disabled': disableUpload,
        }) },
        React.createElement(CnBox, { direction: "row", align: "center", className: "cn-ui-upload-core" },
            readOnly ? null : (React.createElement(AjaxUploader, { ref: ref },
                React.createElement(UploadButton, null))),
            React.createElement("div", { className: "cn-ui-upload-description" }, description)),
        React.createElement(UploadList, null)));
});
