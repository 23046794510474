import React, { useContext } from 'react';
import classNames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnBox } from '@/components/cn-box';
import { UploadButton } from './upload-button';
import { UploadStateContext } from './context';
import { AjaxUploader } from './rc-upload/ajax-uploader';
import { MiniUploadList } from './upload-list/mini-file-list';
export const OSSUploadMini = React.forwardRef((_, ref) => {
    const { props, disableUpload } = useContext(UploadStateContext);
    const { style, className, readOnly, description } = props;
    return (React.createElement("div", { "data-name": "CnMiniUpload", style: style, className: classNames(className, 'cn-ui-upload', 'cn-ui-upload-mini', {
            'cn-ui-upload-readonly': readOnly,
            'cn-ui-upload-disabled': disableUpload,
        }) },
        React.createElement(CnBox, { direction: "row", align: "center", className: "cn-ui-upload-core" }, readOnly ? null : (React.createElement(AjaxUploader, { ref: ref }, description ? (React.createElement(CnTooltip, { trigger: React.createElement(UploadButton, null) }, description)) : (React.createElement(UploadButton, null))))),
        React.createElement(MiniUploadList, null)));
});
