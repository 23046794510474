// 第三方依赖
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import { useAsyncEffect } from 'ahooks';
// 其他组件
import { safeCallFunction } from '@/components/cn-utils';
import { useControlValue } from './use-control-value';
// 给columns添加copyTitle属性
const addCopyTitle = (columns) => {
    return columns.map((item) => {
        if (item.copyTitle) {
            return item;
        }
        item.copyTitle = isString(item.title) ? item.title : item.name;
        return item;
    });
};
export function useControlColumnsValue(props) {
    const [localColumns, setLocalColumns] = React.useState([]);
    const remoteColumnsRef = React.useRef([]);
    const localColumnsRef = React.useRef(props.columns);
    const preColumns = React.useRef();
    const storageKey = React.useMemo(() => {
        var _a;
        return (_a = safeCallFunction(props.storageKey)) !== null && _a !== void 0 ? _a : props.storageKey;
    }, [props.storageKey]);
    React.useEffect(() => {
        if (!Array.isArray(props.columns)) {
            return;
        }
        const columns = addCopyTitle(props.columns);
        // 如果是高性能模式 columns引用变化就认为是columns发生变化
        if (props.ColumnsPerformance) {
            setColumns(columns);
            // 如果不是性能模式则深比较columns是否有变化
        }
        else if (!isEqual(preColumns.current, columns)) {
            // setLocalColumns(props.columns);
            setColumns(columns);
            preColumns.current = columns;
        }
    }, [props.columns, props.ColumnsPerformance]);
    useAsyncEffect(async () => {
        try {
            if (!isFunction(props.onGetStorage))
                return;
            const result = await props.onGetStorage();
            if (!Array.isArray(result.columns))
                return;
            remoteColumnsRef.current = result.columns;
            columnChange(localColumnsRef.current);
        }
        catch (error) { }
    }, []);
    const columnChange = (columns, type) => {
        var _a, _b, _c;
        const columnSet = (_b = (_a = _get(props, 'toolbar.settings', [])) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, (item) => (item === null || item === void 0 ? void 0 : item.type) === 'columnSet');
        const fullColumns = mergeLocalColumns(columns, type, storageKey);
        // 此为临时修复，在 columns 上添加 copyTitle 的行为仅执行一次，也就是将 setColumn 的行为统一
        const addedFullColumns = addCopyTitle(fullColumns);
        setLocalColumns(addedFullColumns);
        localColumnsRef.current = addedFullColumns;
        safeCallFunction((_c = props === null || props === void 0 ? void 0 : props.toolbar) === null || _c === void 0 ? void 0 : _c.columnChange, addedFullColumns, type);
        safeCallFunction(columnSet === null || columnSet === void 0 ? void 0 : columnSet.onChange, addedFullColumns, type);
    };
    /**
     * 合并本地列和远端列
     * @param {ICnTableColumn[]} columns - 当前表格列
     * @param {string} type - 操作类型
     * @param {string} storageKey - 存储键名
     * @returns {ICnTableColumn[]} 合并后的列
     */
    function mergeLocalColumns(columns = [], type, storageKey) {
        /**
         * 获取本地存储的列
         * @returns {ICnTableColumn[]} 本地存储的列
         */
        function getLocalColumns() {
            if (isFunction(props.onGetStorage)) {
                return remoteColumnsRef.current;
            }
            else if (storageKey && !isFunction(props.onGetStorage)) {
                const localColumnStr = localStorage.getItem(`COLUMNS_${storageKey}`);
                return JSON.parse(localColumnStr);
            }
        }
        if (type === 'user') {
            if (storageKey && !isFunction(props.onSetStorage)) {
                const settingColumns = columns.map((item) => ({
                    dataIndex: item.dataIndex,
                    code: item.code,
                    lock: item.lock,
                    align: item.align,
                    hidden: item.hidden,
                    width: item.width,
                }));
                localStorage.setItem(`COLUMNS_${storageKey}`, JSON.stringify(settingColumns));
            }
            safeCallFunction(props.onSetStorage, { columns });
            remoteColumnsRef.current = columns;
            return columns;
        }
        else {
            const localColumns = getLocalColumns();
            if (!Array.isArray(localColumns))
                return columns;
            // 用本地的顺序合并远端columns(本地存储顺序也是列设置)
            // 因为要用本地列和远端列进行匹配,所以这里浅拷贝远端列逐一匹配删除,如果远端列有剩余就是有新增列
            const copyColumns = cloneDeep(columns);
            const mergeColumns = localColumns.reduce((mergeColumns, localItem) => {
                const columnsIndex = copyColumns.findIndex((item) => {
                    if (!isNil(item === null || item === void 0 ? void 0 : item.dataIndex)) {
                        return (localItem === null || localItem === void 0 ? void 0 : localItem.dataIndex) === item.dataIndex;
                    }
                    if (!isNil(item === null || item === void 0 ? void 0 : item.code)) {
                        return (localItem === null || localItem === void 0 ? void 0 : localItem.code) === item.code;
                    }
                    return false;
                });
                if (columnsIndex !== -1) {
                    const mergeColumnsItem = Object.assign(copyColumns[columnsIndex], pick(localItem, [
                        'dataIndex',
                        'code',
                        'lock',
                        'align',
                        'hidden',
                        'width',
                    ]));
                    mergeColumns.push(mergeColumnsItem);
                    copyColumns.splice(columnsIndex, 1);
                }
                return mergeColumns;
            }, []);
            // 如果远端还有剩余列则为新增列放到最后
            return mergeColumns.concat(copyColumns);
        }
    }
    const [columns, setColumns] = useControlValue({
        columns: localColumns,
        onChange: columnChange,
    }, {
        defaultValuePropName: [],
        valuePropName: 'columns',
        changePropName: 'onChange',
    });
    return [columns, setColumns];
}
