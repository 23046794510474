/**
 * 保存查询项配置
 */
import * as React from 'react';
import storage from '../storage';
import { useAsyncReducer, SaveConfigReducers, SaveConfigActionTypes, SaveConfigActions } from '../store';
export const useSaveConfig = ({ enableConfig, // 是否启用配置项
defaultConfigValue, // 默认配置项值
configValue: propConfigValue, // 配置项值
storageKey, // 存储键
children, // 子元素
onGetStorage, // 获取存储数据的回调函数
onSetStorage, // 存储数据的回调函数
 }) => {
    const [state, dispatch] = useAsyncReducer(SaveConfigReducers, {
        configValue: defaultConfigValue || null,
        overlayVisible: false,
        configInited: false,
        itemsMeta: [],
        dataSource: [],
        arrangedChildren: [], // 排列后的子元素
    });
    const { configValue, itemsMeta, dataSource } = state; // 解构 state 中的变量
    const isInitStorageRef = React.useRef(true); // 是否初始化存储引用
    // 监听 propConfigValue 的变化
    React.useEffect(() => {
        if (propConfigValue !== undefined && propConfigValue !== configValue) { // 如果 propConfigValue 有变化
            dispatch({
                type: SaveConfigActionTypes.SetConfigValue,
                payload: propConfigValue,
            });
        }
    }, [configValue, propConfigValue]);
    const storageRef = React.useRef(// 存储引用
    storage({
        storageKey,
        type: 'config',
        getData: onGetStorage,
        setData: onSetStorage,
    }));
    // 监听 enableConfig 的变化
    React.useEffect(() => {
        if (!enableConfig)
            return; // 如果未启用配置项，直接返回
        storageRef.current // 获取存储数据
            .getData()
            .then((data) => {
            isInitStorageRef.current = false; // 标记存储引用已初始化
            if (data) { // 如果有存储数据，更新配置项值
                dispatch({
                    type: SaveConfigActionTypes.SetConfigValue,
                    payload: data,
                });
            }
        })
            .catch(() => { }).finally(() => {
            dispatch({
                type: SaveConfigActionTypes.SetConfigInited,
                payload: true,
            });
        });
    }, [enableConfig]);
    // 监听 configValue 的变化
    React.useEffect(() => {
        if (!enableConfig)
            return; // 如果未启用配置项，直接返回
        if (isInitStorageRef.current)
            return; // 如果存储引用未初始化，直接返回
        storageRef.current.setData(configValue).catch(() => { }); // 存储配置项值
    }, [configValue, enableConfig]);
    // 监听 enableConfig 和 children 的变化
    React.useEffect(() => {
        dispatch(SaveConfigActions.updateItemsMeta(state, {
            enableConfig, children,
        }));
    }, [enableConfig, children]);
    // 监听 configValue、itemsMeta 和 enableConfig 的变化
    React.useEffect(() => {
        dispatch(SaveConfigActions.updateDataSource(state, {
            enableConfig,
        }));
    }, [configValue, itemsMeta, enableConfig]);
    // 监听 children、dataSource 和 enableConfig 的变化
    React.useEffect(() => {
        dispatch(SaveConfigActions.updateArrangedChildren(state, {
            enableConfig,
            children,
        }));
    }, [children, dataSource, enableConfig]);
    return [state, dispatch]; // 返回 state 和 dispatch
};
