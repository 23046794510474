import * as React from 'react';
import pick from 'lodash/pick';
import classnames from 'classnames';
import isPlainObject from 'lodash/isPlainObject';
// 其他组件
import { domStyleMap } from '@/components/cn-utils';
import { CnTooltip } from '@/components/cn-tooltip';
// 相对依赖
import { renderValueHandleEmpty } from './utils';
export default () => {
    return {
        cell(value) {
            // if (typeof value === 'string') {
            // }
            if (isPlainObject(value) && typeof value === 'object') {
                // 对象传入，目前支持ToolTips,link
                // const linkStyle = value?.link ? { color: '#284cc0' } : {}; // 是否带超链
                const styleAttribute = pick(value, Object.keys(domStyleMap));
                const textDom = (React.createElement("span", { className: classnames({
                        'cn-table-format-text-link': !!(value === null || value === void 0 ? void 0 : value.link),
                    }), style: styleAttribute, onClick: () => (value === null || value === void 0 ? void 0 : value.link) && window.open(value === null || value === void 0 ? void 0 : value.link) }, renderValueHandleEmpty(value.text)));
                if (value === null || value === void 0 ? void 0 : value.toolTips) {
                    // toolsTips
                    const renderContent = (data) => {
                        return (React.createElement("div", null, data.map((item) => {
                            return React.createElement("div", null, item.desc);
                        })));
                    };
                    if (Array.isArray(value === null || value === void 0 ? void 0 : value.toolTips)) {
                        return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderContent(value.toolTips)));
                    }
                    else {
                        return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderValueHandleEmpty(value.toolTips)));
                    }
                }
                return textDom;
            }
            return renderValueHandleEmpty(value);
        },
    };
};
