// 第三方依赖
import * as React from 'react';
import cx from 'classnames';
import $i18n from 'panda-i18n';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
// 其他组件
import { CnTooltip } from '@/components/cn-tooltip';
import { CascaderSelect as NextCascaderSelect, } from '@/components/fusion';
import { CnReadOnly } from '@/components/cn-read-only';
import { i18nRegister, useCnRequest } from '@/components/cn-utils';
// 相对依赖
import { findDeep } from './utils';
import { handleErrorInfo } from './handle-error-info';
import { handleLoading } from './handle-loading';
// 当前目录的相对依赖
import './cn-cascader-select.scss';
// 导出一个名为 CnCascaderSelect 的 React 函数组件，该组件接收 CnCascaderSelectProps 类型的 props
export const CnCascaderSelect = React.forwardRef((props, ref) => {
    // 从 props 中解构出各个属性
    const { className, readOnly, readOnlyProps, popupContainer, requestConfig, onVisibleChange: onVisibleChangeProps, onChange: onChangeProps, filterLocal, enableRemoteLazyLoad, ...otherProps } = props;
    // 定义一个名为 innerDataSource 的 state，初始值为空数组
    const [innerDataSource, setInnerDataSource] = React.useState([]);
    // 定义一个名为 existValues 的 ref，初始值为 undefined
    const existValues = React.useRef();
    // 定义一个名为 remoteLazyLoadItemRef 的 ref，初始值为 undefined
    const remoteLazyLoadItemRef = React.useRef();
    // 定义一个名为 selectRef 的 ref，初始值为 null
    const selectRef = React.useRef(null);
    // 定义一个名为 insertSelectProps 的对象，用于存储 CascaderSelect 组件的 props
    const insertSelectProps = {};
    // 定义一个名为 isRemoteDataSource 的 useMemo，用于判断是否是远程数据源
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    // 如果 requestConfig 中没有 resultFormatter 和 formatResult 属性，则为其添加一个默认的 formatResult 方法
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = (res) => {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    // 使用 useCnRequest 自定义 hook 发送请求，获取数据
    const { run, runAsync, error, data, loading, mutate } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    // 使用 React.useImperativeHandle 设置 ref 的实例值
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            sendRequest: run,
            ...((_a = selectRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    }, [selectRef]);
    // 定义一个名为 maxTagPlaceholder 的函数，用于生成标签占位符
    const maxTagPlaceholder = (selectedValues) => {
        const trigger = (React.createElement("span", { className: "cn-next-select-tag-compact-inner" },
            $i18n.get({ id: 'Selected', dm: '已选择', ns: 'CnAsyncSelect' }),
            `${selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.length}`,
            $i18n.get({
                id: 'APOLLO_X.Item.import.CNTM',
                dm: '项',
                ns: 'CnAsyncSelect',
            })));
        const labels = selectedValues === null || selectedValues === void 0 ? void 0 : selectedValues.map((obj) => {
            if (typeof obj === 'object') {
                return obj.label;
            }
            return obj;
        });
        return (React.createElement(CnTooltip, { trigger: trigger }, labels && labels.join(', ')));
    };
    // 使用 React.useEffect 监听 props.value 的变化，如果 existValues 不等于 props.value，则清空 existValues
    React.useEffect(() => {
        if (!('value' in props))
            return;
        let ev = existValues.current;
        ev = Array.isArray(ev) ? ev.map((i) => i.value) : ev === null || ev === void 0 ? void 0 : ev.value;
        if (!isEqual(ev, props.value)) {
            existValues.current = undefined;
        }
    }, [props.value]);
    // 使用 React.useEffect 监听 data 的变化，如果 data 变化，则更新 innerDataSource
    React.useEffect(() => {
        if (loading)
            return;
        setInnerDataSource((previousDataSource) => {
            var _a;
            const rst = [];
            const ev = existValues.current;
            if (enableRemoteLazyLoad) {
                if (!((_a = remoteLazyLoadItemRef.current) === null || _a === void 0 ? void 0 : _a.value)) {
                    return data;
                }
                const valueItem = findDeep(previousDataSource, 'value', remoteLazyLoadItemRef.current.value);
                if (valueItem) {
                    valueItem.children = data;
                }
                return previousDataSource;
            }
            if (Array.isArray(ev)) {
                ev.length > 0 &&
                    ev.map((i) => {
                        if (!findDeep(data, 'value', i.value)) {
                            rst.push({
                                value: i.value,
                                label: i.label,
                            });
                        }
                    });
                return data.concat(rst);
            }
            if (ev && ev.value && !findDeep(data, 'value', ev.value)) {
                rst.push({
                    value: ev.value,
                    label: ev.label,
                });
                return data.concat(rst);
            }
            return data;
        });
    }, [data]);
    // 将 innerDataSource 赋值给 insertSelectProps.dataSource
    insertSelectProps.dataSource = innerDataSource || [];
    // 如果是远程数据源，则将 insertSelectProps.resultAutoWidth 设置为 false
    if (isRemoteDataSource) {
        insertSelectProps.resultAutoWidth = false;
    }
    // 调用 handleErrorInfo 函数处理错误信息，并将返回的对象赋值给 errorInfo
    const errorInfo = handleErrorInfo(error);
    // 调用 handleLoading 函数处理加载状态，并将返回的对象赋值给 loadingInfo
    const loadingInfo = handleLoading(loading, data);
    // 将 errorInfo 和 loadingInfo 合并到 insertSelectProps 中
    Object.assign(insertSelectProps, errorInfo, loadingInfo);
    // 如果开启了远程异步加载数据，则关闭搜索功能，并设置 loadData 方法
    if (enableRemoteLazyLoad) {
        otherProps.showSearch = false;
        otherProps.loadData = (item) => {
            remoteLazyLoadItemRef.current = item;
            return runAsync({
                [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            }).catch(err => {
                console.log(err);
            });
        };
    }
    // 如果开启了搜索功能且没有设置 onSearch 方法且 filterLocal 为 false，则设置 onSearch 方法和 filter 方法
    const enableOnSearch = otherProps.showSearch && !otherProps.onSearch && !filterLocal;
    if (enableOnSearch) {
        insertSelectProps.onSearch = (inputValue) => {
            run({
                [requestConfig.searchKey || 'key']: inputValue,
            });
        };
        insertSelectProps.filter = () => true;
    }
    // 定义一个名为 onVisibleChange 的函数，用于处理下拉框显示隐藏的状态变化
    const onVisibleChange = (visible, type) => {
        if (typeof onVisibleChangeProps === 'function') {
            onVisibleChangeProps(visible, type, run);
        }
    };
    // 定义一个名为 onChange 的函数，用于处理选中值的变化
    const onChange = (v, d, e) => {
        if (isRemoteDataSource) {
            existValues.current = d;
            if (!v || !v.length) {
                run({
                    [requestConfig.searchKey || 'key']: '',
                });
            }
        }
        if (typeof onChangeProps === 'function') {
            onChangeProps(v, d, e);
        }
    };
    // 定义一个名为 renderPreview 的函数，用于渲染预览模式下的值
    const renderPreview = (values) => {
        const otherPlaceProps = pick(props, [
            'addonBefore',
            'innerBefore',
            'addonTextBefore',
            'addonTextAfter',
            'innerAfter',
            'addonAfter',
        ]);
        return (React.createElement(CnReadOnly, { value: values, valueSeparator: ' / ', ...otherPlaceProps, ...readOnlyProps }));
    };
    // 返回一个 NextCascaderSelect 组件
    return (React.createElement(NextCascaderSelect, { "data-testid": "CnCascaderSelect", "data-name": "CnCascaderSelect", className: cx({
            'cn-ui-cascader-select': true,
        }, className), ref: selectRef, isPreview: readOnly, renderPreview: renderPreview, maxTagPlaceholder: maxTagPlaceholder, popupContainer: popupContainer !== null && popupContainer !== void 0 ? popupContainer : ((e) => (e === null || e === void 0 ? void 0 : e.closest('#cn-ui-page-scroll-container')) || document.body), ...insertSelectProps, ...omit(otherProps, ['$i18n']), onVisibleChange: onVisibleChange, onChange: onChange }));
});
CnCascaderSelect.displayName = 'CnCascaderSelect';
CnCascaderSelect.defaultProps = {
    maxTagCount: 2,
    tagInline: true,
    requestConfig: {},
    readOnlyProps: {
        valueSeparator: ' / ',
        type: 'enum',
    },
    filterLocal: true,
};
i18nRegister(CnCascaderSelect);
