import React, { useRef, useState, useCallback, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import mapKeys from 'lodash/mapKeys';
import cloneDeepWith from 'lodash/cloneDeepWith';
import get from 'lodash/get';
import { getRequest, request } from 'cn-request';
import queryString from 'query-string';
export { generateEffects } from './generateEffects';
export const cloneDeepWithReactElement = (source) => cloneDeepWith(source, (value) => {
    if (React.isValidElement(value)) {
        return value;
    }
});
const SchemaMap = {
    initialValue: 'default',
    display: 'x-display',
    component: 'x-component',
    uipattern: 'x-pattern',
    dataSource: 'enum',
    reactions: 'x-reactions',
    validator: 'x-validator',
    index: 'x-index',
    decorator: 'x-decorator',
    content: 'x-content',
    visible: 'x-visible',
    hidden: 'x-hidden',
    disabled: 'x-disabled',
    editable: 'x-editable',
    readOnly: 'x-read-only',
    readPretty: 'x-read-pretty',
    data: 'x-data',
    'decorator-props': 'x-decorator-props',
    decoratorProps: 'x-decorator-props',
    xDecoratorProps: 'x-decorator-props',
    'component-props': 'x-component-props',
    componentProps: 'x-component-props',
    xComponentProps: 'x-component-props',
};
const CnCard = 'CnCard';
const CnCardSubCard = 'CnCardSubCard';
const CnFormGrid = 'CnFormGrid';
const ExtraSchema = { 'x-decorator': 'CnFormItem' };
const formattedSchemaKeys = (obj) => mapKeys(obj, (value, key) => { var _a; return (_a = SchemaMap === null || SchemaMap === void 0 ? void 0 : SchemaMap[key]) !== null && _a !== void 0 ? _a : key; });
const generateFormItemSchema = (item, cols) => {
    const itemConfig = formattedSchemaKeys(item);
    const { name, title, 'x-component-props': componentProps = {}, ...restProps } = itemConfig;
    const { cols: subCardCols } = componentProps;
    const formGridProps = {};
    if (subCardCols) {
        formGridProps.cols = subCardCols;
    }
    else {
        formGridProps.cols = cols;
    }
    if (item.component === CnCard) {
        return {
            type: 'void',
            'x-component': CnCard,
            'x-component-props': {
                title,
                foldable: true,
                style: { marginBottom: '16px' },
                ...componentProps,
            },
            properties: {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            },
            ...restProps,
        };
    }
    else if (item.component === CnCardSubCard) {
        return {
            type: 'void',
            'x-component': CnCardSubCard,
            'x-component-props': {
                subTitle: title,
                ...componentProps,
            },
            properties: {
                [`${name}_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': formGridProps,
                    properties: {},
                },
            },
            ...restProps,
        };
    }
    else {
        return {
            ...ExtraSchema,
            ...itemConfig,
        };
    }
};
const list2FormilySchema = (list, cols) => {
    let currentParent = 'root';
    const tree = {
        root: {
            type: 'object',
            properties: {
                [`root_${CnFormGrid}`]: {
                    type: 'void',
                    'x-component': CnFormGrid,
                    'x-component-props': {
                        cols,
                    },
                    properties: {},
                },
            },
            _component: 'root',
            _name: 'root',
        },
    };
    if (Array.isArray(list) && list.length > 0) {
        const _list = cloneDeepWithReactElement(list);
        const ifContainer = (component) => component === 'CnCard' || component === 'CnCardSubCard';
        _list.forEach((item = {}, index) => {
            var _a;
            const { component } = item;
            const isContainer = ifContainer(component);
            const parent = tree[currentParent];
            if (isContainer) {
                item.name = `container_${component}_${index}`;
            }
            const name = item.name;
            if (isContainer) {
                if (component === CnCard) {
                    tree[name] = generateFormItemSchema(item, cols);
                    tree[name]._parent = 'root';
                }
                else if (component === CnCardSubCard) {
                    if ((parent === null || parent === void 0 ? void 0 : parent._component) === CnCard || (parent === null || parent === void 0 ? void 0 : parent._component) === 'root') {
                        tree[name] = generateFormItemSchema(item, cols);
                        if (tree[name]) {
                            tree[name]._parent = currentParent;
                        }
                    }
                    else if ((parent === null || parent === void 0 ? void 0 : parent._component) === CnCardSubCard) {
                        const temp = (parent === null || parent === void 0 ? void 0 : parent._parent) || 'root';
                        tree[name] = generateFormItemSchema(item, cols);
                        if (tree[name]) {
                            tree[name]._parent = tree[temp]._name || 'root';
                        }
                    }
                }
                // 先判断是否是「容器」，是的话，parant指针指向最近的容器。那么下一个input的_parent就会指向最近的容器
                currentParent = name;
            }
            else {
                tree[name] = generateFormItemSchema(item, cols);
                if (tree[name]) {
                    tree[name]._parent = currentParent;
                }
            }
            if (tree[name]) {
                tree[name]._name = name;
                tree[name]._component = component;
            }
            if ((_a = tree[name]) === null || _a === void 0 ? void 0 : _a._parent) {
                const parentSchema = tree[tree[name]._parent];
                if (parentSchema._component === CnCardSubCard) {
                    const { _name } = parentSchema;
                    const formGrid = parentSchema.properties[`${_name}_${CnFormGrid}`];
                    if (formGrid === null || formGrid === void 0 ? void 0 : formGrid.properties) {
                        formGrid.properties[name] = tree[name];
                    }
                }
                else if (parentSchema._component === 'root' && !isContainer) {
                    const formGrid = parentSchema.properties[`root_${CnFormGrid}`];
                    if (formGrid === null || formGrid === void 0 ? void 0 : formGrid.properties) {
                        formGrid.properties[name] = tree[name];
                    }
                }
                else if (parentSchema._component === CnCard) {
                    if (component === CnCardSubCard) {
                        parentSchema.properties[name] = tree[name];
                    }
                    else {
                        const { _name } = parentSchema;
                        const formGrid = parentSchema.properties[`${_name}_${CnFormGrid}`];
                        if (formGrid === null || formGrid === void 0 ? void 0 : formGrid.properties) {
                            formGrid.properties[name] = tree[name];
                        }
                    }
                }
                else {
                    parentSchema.properties[name] = tree[name];
                }
            }
        });
    }
    return tree.root;
};
export const generateSchema = (schema, cols, onlyProperties = false) => {
    if (isEmpty(schema))
        return {};
    if (!isArray(schema))
        return schema;
    const finalSchema = list2FormilySchema(schema, cols);
    if (onlyProperties) {
        return {
            type: 'void',
            colSpan: 'full',
            properties: finalSchema.properties,
        };
    }
    return finalSchema;
};
const packRequest = (options) => {
    if (!getRequest()) {
        console.error('组件使用默认实例中...请先初始化项目cn-request,切勿发布到生产环境');
    }
    return request(options).then((result) => {
        if ('request' in result && 'config' in result) {
            return result.data;
        }
        return result;
    });
};
export const handleRequestService = (requestConfig) => {
    const { url, service, params: paramsProps, data: dataProps, searchFormat = (data) => data, method = 'GET', headers = {}, } = requestConfig || {};
    if (service) {
        return service;
    }
    if (url) {
        return (searchConfig) => {
            const { formValues } = searchConfig || {};
            let realDataProps = dataProps;
            // application/x-www-form-urlencoded时，parse为对象后再进行解构；否则会是键值对字符串，导致解构出错
            if ((headers === null || headers === void 0 ? void 0 : headers['content-type']) === 'application/x-www-form-urlencoded') {
                realDataProps = queryString.parse(dataProps);
            }
            const params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                ? searchFormat({ ...formValues, ...paramsProps })
                : paramsProps;
            const data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                ? searchFormat({ ...formValues, ...realDataProps })
                : realDataProps;
            return packRequest({
                ...requestConfig,
                params,
                data,
                url,
            });
        };
    }
    return () => Promise.resolve([]);
};
export function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);
    const setStateCallback = useCallback((_state, cb) => {
        cbRef.current = cb;
        setState(_state);
    }, []);
    useEffect(() => {
        if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
        }
    }, [state]);
    return [state, setStateCallback];
}
export function useIsExceedWidth(width) {
    const [isExceed, setIsExceed] = useState(document.documentElement.clientWidth >= width);
    const resizeHandle = useCallback(() => {
        setIsExceed(document.documentElement.clientWidth >= width);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', resizeHandle);
        return () => window.removeEventListener('resize', resizeHandle);
    }, []);
    return isExceed;
}
export const getTargetArray = (source, checkList = [], checkSource = true) => {
    let result = [];
    if (checkSource) {
        if (isArray(source))
            return source;
    }
    for (let i = 0; i < checkList.length; i++) {
        const targetValue = get(source, checkList[i]);
        if (isArray(targetValue)) {
            result = targetValue;
            break;
        }
    }
    return result;
};
