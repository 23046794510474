import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
export default (value) => {
    if (isNil(value) || value === '') {
        return '- -';
    }
    const valueNumber = new BigNumber(value);
    const valueTypeVerify = typeof value === 'string' || typeof value === 'number';
    const valueIsLikeNumber = typeof valueNumber.toNumber() === 'number';
    const canMicrometer = valueTypeVerify && valueIsLikeNumber;
    if (canMicrometer) {
        return valueNumber.toFormat();
    }
    return value;
};
