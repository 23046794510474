// 该函数用于计算间距大小
// size: 间距大小，可选值为 'small' | 'medium' | 'large'
// defaultGap: 默认间距大小，可选值为 number | string | {(size: string): number | string;}
export const useGap = (size, defaultGap) => {
    if (typeof defaultGap === 'function')
        return defaultGap(size); // 如果 defaultGap 是函数，则返回该函数的返回值
    if (defaultGap)
        return defaultGap; // 如果 defaultGap 存在，则返回 defaultGap
    if (size === 'large')
        return 16; // 如果 size 为 'large'，则返回 16
    if (size === 'small')
        return 8; // 如果 size 为 'small'，则返回 8
    return 12; // 如果 size 为 'medium'，则返回 12
};
