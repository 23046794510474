// @ts-nocheck
import * as React from 'react';
import { CnTree } from '@/components/cn-tree';
import { safeCallFunction } from '@/components/cn-utils/helpers';
import { normalizeToArray } from '@/components/cn-tree/view/util';
import { valueToSelectKey } from '@fusion/lib/select/util';
import { flatDataSource } from '@/components/cn-tree-select/tree-select/tree-select';
export const Tree = React.forwardRef((props, ref) => {
    const isControlled = 'value' in props;
    const { value: propValue, defaultValue, treeArea, children, dataSource, checkable, checkedStrategy, onChange = () => { }, ...others } = props;
    const [value, setValue] = React.useState(normalizeToArray(isControlled ? propValue : defaultValue).map((v) => {
        return valueToSelectKey(v);
    }));
    const [, forceUpdate] = React.useState(1);
    const datasetRef = React.useRef(flatDataSource(props));
    const treeRef = React.useRef(null);
    const { _k2n, _p2n, _v2n } = datasetRef.current;
    const treeProps = {};
    React.useEffect(() => {
        if (isControlled && value !== propValue) {
            setValue(normalizeToArray(propValue).map((v) => {
                return valueToSelectKey(v);
            }));
        }
    }, [propValue]);
    React.useEffect(() => {
        datasetRef.current = flatDataSource(props);
        forceUpdate((s) => (s + 1) % 32);
    }, [dataSource, children]);
    const getKeysByValue = (value) => {
        const { _v2n } = datasetRef.current;
        return value.reduce((ret, v) => {
            const k = _v2n[v] && _v2n[v].key;
            if (k) {
                ret.push(k);
            }
            return ret;
        }, []);
    };
    const getValueByKeys = (keys) => {
        const { _k2n } = datasetRef.current;
        return keys.map((k) => { var _a; return (_a = _k2n[k]) === null || _a === void 0 ? void 0 : _a.value; });
    };
    const getData = (value) => {
        const ret = value.reduce((ret, v) => {
            var _a;
            const k = _v2n[v] && _v2n[v].key;
            if (k) {
                // console.log(_k2n[k]);
                // const { label, pos } = _k2n[k];
                const d = {
                    value: v,
                    // label,
                    ...((_a = _k2n[k]) !== null && _a !== void 0 ? _a : {}),
                    // pos,
                };
                d.key = k;
                ret.push(d);
            }
            return ret;
        }, []);
        return ret;
    };
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            ...((_a = treeRef.current) !== null && _a !== void 0 ? _a : {}),
            getKeysByValue,
            getValueByKeys,
            datasetRef,
        };
    }, [treeRef]);
    const handleSelect = (selectedKeys, extra) => {
        if (!(extra === null || extra === void 0 ? void 0 : extra.selected)) {
            return;
        }
        const value = getValueByKeys(selectedKeys);
        if (!isControlled) {
            setValue(value);
        }
        const data = getData(value);
        if (props.multiple) {
            safeCallFunction(onChange, value, data);
        }
        else {
            safeCallFunction(onChange, value[0], data[0]);
        }
        // props.multiple ? onChange(value, data) : onChange(value[0], data[0]);
    };
    const handleCheck = (checkedKeys) => {
        const value = getValueByKeys(checkedKeys);
        if (!isControlled) {
            setValue(value);
        }
        safeCallFunction(onChange, value, getData(value));
        // onChange(value, getData(value));
    };
    if (treeArea) {
        treeProps.isLabelBlock = true;
        treeProps.labelRender = (node) => {
            var _a, _b, _c;
            const selfValue = getValueByKeys([node === null || node === void 0 ? void 0 : node.key]);
            node.value = Array.isArray(selfValue) ? selfValue[0] : selfValue;
            return (React.createElement("div", { className: "cn-async-tree-label", style: {
                    display: 'flex',
                    flexGrow: 1,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    position: 'relative',
                } },
                React.createElement("div", null, node === null || node === void 0 ? void 0 : node.label),
                ((_a = value === null || value === void 0 ? void 0 : value.toString) === null || _a === void 0 ? void 0 : _a.call(value)) == ((_c = (_b = node === null || node === void 0 ? void 0 : node.value) === null || _b === void 0 ? void 0 : _b.toString) === null || _c === void 0 ? void 0 : _c.call(_b)) && (React.createElement("div", { className: "cn-async-tree-label-area", style: {
                        position: 'absolute',
                        right: 0,
                        backgroundColor: '#fff',
                    } }, treeArea(node === null || node === void 0 ? void 0 : node.key, node)))));
        };
    }
    const keys = getKeysByValue(value);
    if (checkable) {
        treeProps.checkable = checkable;
        treeProps.checkedStrategy = props.checkStrictly ? 'all' : checkedStrategy;
        treeProps.checkedKeys = keys;
        treeProps.onCheck = handleCheck;
    }
    else {
        treeProps.selectedKeys = keys;
        treeProps.onSelect = handleSelect;
    }
    return (React.createElement(CnTree, { ref: treeRef, ...others, ...treeProps, dataSource: dataSource }));
});
Tree.displayName = 'Tree';
