import isFunction from 'lodash/isFunction';
import { isLeafNode as sourceStandardIsLeafNode } from '../../utils';
// 判断是否为叶子节点的函数
const isLeafNodeStandard = (node, details) => {
    // 判断是否有子节点
    let hasChildren = false;
    if (node.hasChildren === true) {
        hasChildren = true;
    }
    else if (isFunction(node === null || node === void 0 ? void 0 : node.hasChildren)) {
        hasChildren = (node === null || node === void 0 ? void 0 : node.hasChildren(node, details)) === true;
    }
    else {
        hasChildren = !sourceStandardIsLeafNode(node);
    }
    // 如果有子节点，则不是叶子节点
    return !hasChildren;
};
export default isLeafNodeStandard;
