// @ts-nocheck
import * as React from 'react';
import Search from '@fusion/lib/search';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import { useCnRequest } from '@cainiaofe/cn-ui-utils';
import { Tree } from './tree';
export const FormTree = React.forwardRef((props, ref) => {
    var _a, _b;
    const { requestConfig, showSearch, enableRemoteLazyLoad, searchProps = {}, filterLocal = true, ...others } = props;
    const baseTreeRef = React.useRef(null);
    const treeItemRef = React.useRef(null);
    const [innerDataSource, setInnerDataSource] = React.useState([]);
    const [matchedKeys, setMatchedKeys] = React.useState([]);
    const [expandedKeys, setExpandedKeys] = React.useState([]);
    const treeProps = {};
    const getItemByPos = (pos) => {
        if (!pos)
            return false;
        return pos
            .split('-')
            .slice(1)
            .reduce((ret, num) => ret.children[num], { children: innerDataSource });
    };
    const isRemoteDataSource = React.useMemo(() => {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    const formatResult = (res) => {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    };
    if (!requestConfig.formatResult) {
        requestConfig.formatResult = formatResult;
    }
    const { runAsync, data = [], run, mutate, } = useCnRequest({
        ready: isRemoteDataSource,
        ...requestConfig,
    });
    function fetchData() {
        return runAsync().then((dataSource) => {
            console.log(dataSource);
            setInnerDataSource(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.formatResult(dataSource));
        });
    }
    if (props === null || props === void 0 ? void 0 : props.instance) {
        if (isPlainObject((_b = (_a = props === null || props === void 0 ? void 0 : props.instance) === null || _a === void 0 ? void 0 : _a.remoteOperation) === null || _b === void 0 ? void 0 : _b.current)) {
            // @ts-ignore
            props.instance.remoteOperation.current.fetchData = fetchData;
        }
    }
    React.useEffect(() => {
        if ((data === null || data === void 0 ? void 0 : data.length) === 0 && (innerDataSource === null || innerDataSource === void 0 ? void 0 : innerDataSource.length) === 0) {
            return;
        }
        if (treeItemRef.current) {
            treeItemRef.current.children = data;
            setInnerDataSource((data) => [...data]);
            treeItemRef.current = null;
        }
        else {
            setInnerDataSource(formatResult(data));
        }
    }, [data]);
    if (isRemoteDataSource) {
        treeProps.dataSource = innerDataSource;
    }
    const enableOnSearch = showSearch && !others.onSearch;
    // const localSearch = showSearch && others.filterLocal;
    if (enableOnSearch) {
        if (filterLocal) {
            searchProps.onChange = (value) => {
                value = value.trim();
                if (!value) {
                    setMatchedKeys([]);
                    setExpandedKeys([]);
                    return;
                }
                const localMatchedKeys = [];
                const loop = (data) => data.forEach((item) => {
                    if (item.label.indexOf(value) > -1) {
                        localMatchedKeys.push(item.key);
                    }
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                });
                loop(!isRemoteDataSource && others.dataSource
                    ? others.dataSource
                    : innerDataSource);
                setMatchedKeys(localMatchedKeys);
                setExpandedKeys([...localMatchedKeys]);
                treeProps.autoExpandParent = true;
            };
            treeProps.filterTreeNode = (node) => {
                return (!isEmpty(matchedKeys) &&
                    matchedKeys.indexOf(node.props.eventKey) > -1);
            };
            treeProps.expandedKeys = expandedKeys;
            // 展开收起时触发
            treeProps.onExpand = (keys, extra) => {
                // 展开
                if (extra.expanded) {
                    setExpandedKeys(keys);
                    treeProps.autoExpandParent = false;
                }
                else {
                    // 检测 extra.node 里面的 children 是否有keys，如果有则删除
                    // 解决展开父层级再展开子层级，父层级无法收起的问题
                    const loop = (node) => node.props.children.forEach((item) => {
                        if (keys.includes(item.key)) {
                            keys.splice(keys.indexOf(item.key), 1);
                        }
                        if (item.props.children) {
                            loop(item);
                        }
                    });
                    loop(extra.node);
                    setExpandedKeys(keys);
                    treeProps.autoExpandParent = false;
                }
            };
        }
        else {
            searchProps.onSearch = (inputValue) => {
                var _a;
                run({ [(_a = requestConfig.searchKey) !== null && _a !== void 0 ? _a : 'searchKey']: inputValue });
            };
        }
    }
    if (enableRemoteLazyLoad) {
        treeProps.loadData = (node) => {
            var _a;
            const item = getItemByPos((_a = node === null || node === void 0 ? void 0 : node.props) === null || _a === void 0 ? void 0 : _a.pos);
            if (!item)
                return false;
            return runAsync({
                [requestConfig.remoteLazyLoadKey || 'value']: item.value,
            }).then((result) => {
                // requestConfig.formatResult(result);
                // if (node.props.children) {
                //   return true;
                // }
                treeItemRef.current = item;
            });
        };
    }
    React.useImperativeHandle(ref, () => {
        var _a;
        return {
            mutateDataSource: mutate,
            // 拼写错误，需修正
            sandRequest: (...args) => {
                console.log('[CnAsyncTree] 拼写错误，需修正为 sendRequest，此 API 会在后续版本静默移除');
                run(...args);
            },
            sendRequest: run,
            dataSource: treeProps.dataSource,
            ...((_a = baseTreeRef === null || baseTreeRef === void 0 ? void 0 : baseTreeRef.current) !== null && _a !== void 0 ? _a : {}),
        };
    });
    return (React.createElement(React.Fragment, null,
        showSearch && (React.createElement(Search, { shape: "simple", size: "medium", style: { margin: '10px 0', width: '100%' }, ...searchProps })),
        React.createElement(Tree, { ref: baseTreeRef, ...others, ...treeProps })));
});
FormTree.displayName = 'FormTree';
FormTree.useRemote = function () {
    const ref = React.useRef({});
    const fetch = React.useCallback((...args) => {
        var _a, _b;
        // @ts-ignore
        if (typeof ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData) === 'function') {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData(...args);
        }
    }, [ref.current]);
    const load = React.useCallback(() => {
        return fetch(false); // 不作数据刷新
    }, []);
    const getInnerData = React.useCallback(() => {
        var _a;
        // @ts-ignore
        return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.innerInfo;
    }, []);
    return {
        load,
        remoteOperation: ref,
        getInnerData,
    };
};
FormTree.createRemoteInstance = function () {
    const ref = {
        current: {},
    };
    const fetch = (...args) => {
        var _a, _b;
        // @ts-ignore
        if (typeof ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData) === 'function') {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData(...args);
        }
    };
    const refresh = () => {
        fetch(true);
    };
    const load = () => {
        return fetch(false);
    };
    return {
        refresh,
        load,
        remoteOperation: ref,
    };
};
FormTree.defaultProps = {
    requestConfig: {},
};
