import './index.scss';
import React, { useContext } from 'react';
import cx from 'classnames';
import { UploadListItem } from '../upload-list-item';
import { UploadStateContext } from '../context';
export const UploadList = () => {
    const { value, props } = useContext(UploadStateContext);
    const { limit, showUploadList } = props;
    if (!showUploadList)
        return null;
    if (!Array.isArray(value))
        return null;
    if (!(value === null || value === void 0 ? void 0 : value.length))
        return null;
    if (limit === 1) {
        return (React.createElement("div", { className: cx('cn-ui-upload-single', 'cn-ui-upload-list-normal') },
            React.createElement(UploadListItem, { file: value[0] })));
    }
    return (React.createElement("div", { className: cx('cn-ui-upload-list', 'cn-ui-upload-list-normal') }, value.map((item) => (React.createElement(UploadListItem, { key: item.key, file: item })))));
};
