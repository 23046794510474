import './index.scss';
import React, { useEffect, useMemo, useContext } from 'react';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { CnScrollBar } from '@/components/cn-scroll-bar';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { isKeyParent } from './utils';
import { ShellStateCtx } from '../context';
import { SideBarFooter } from './side-bar-footer';
import { SideBarTopSlot } from './side-bar-top-slot';
const SIDEBAR_WIDTH = {
    FOLD: 60,
    UNFOLD: 224,
};
export const SideBar = () => {
    const { sideBarProps, isSideBarFold, isSideBarPreview, setIsSideBarPreview, sideBarExpandKeys, setSideBarExpandKeys, } = useContext(ShellStateCtx);
    const { leafItemIconRender, menu = [], selectedMenuKey, onSelectMenuItem, hideMenuItemIcon, } = sideBarProps || {};
    // 是否是隐藏一级菜单 icon 的收起状态
    const isHideMenuItemIconFold = useMemo(() => hideMenuItemIcon && isSideBarFold && !isSideBarPreview, [hideMenuItemIcon, isSideBarFold, isSideBarPreview]);
    let cancelPreview = false; // 用来控制 preview 状态展示延迟
    useEffect(() => {
        if (isSideBarFold) {
            document.body.classList.add('sidebar-fold');
        }
        else {
            document.body.classList.remove('sidebar-fold');
        }
    }, [isSideBarFold]);
    // 当侧边栏为收起状态时候，移上去要预览
    const handleExpandAside = (e) => {
        var _a, _b;
        cancelPreview = false;
        const domClassList = ((_b = (_a = e.nativeEvent) === null || _a === void 0 ? void 0 : _a.toElement) === null || _b === void 0 ? void 0 : _b.classList) || [];
        if (domClassList.contains('cn-ui-sidebar-fold'))
            return;
        if (!isSideBarFold || isSideBarPreview)
            return;
        const timer = setTimeout(() => {
            clearTimeout(timer);
            if (cancelPreview) {
                cancelPreview = false;
                return;
            }
            setIsSideBarPreview(true);
        }, 100);
    };
    // 当侧边栏为预览态的时候，移出要取消预览
    const handleFoldAside = () => {
        cancelPreview = true;
        setIsSideBarPreview(false);
    };
    /**
     * 渲染单个菜单子项内容
     */
    const renderMenuItem = (menuItem, level) => {
        var _a, _b;
        if (level >= 3) {
            console.warn('[cn-ui] 设计规范上侧边菜单不支持第四级菜单（总第五级菜单）');
        }
        let itemText = menuItem.text;
        if (isHideMenuItemIconFold) {
            itemText = /^[A-Za-z0-9]+$/.test(itemText.substring(0, 3))
                ? itemText.substring(0, 3)
                : itemText.substring(0, 2);
            itemText = menuItem.abbrText ? menuItem.abbrText : itemText;
        }
        const rightIcon = isHideMenuItemIconFold ? null : (React.createElement(React.Fragment, null,
            ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.length) && level < 3 ? (React.createElement(CnIcon, { type: "arrow-right", size: "small", outerClassName: "cn-ui-shell-menu-item-right-icon", className: classNames({ activated: sideBarExpandKeys.includes(menuItem.key) }, 'sidebar-triangle') })) : null,
            !((_b = menuItem.children) === null || _b === void 0 ? void 0 : _b.length) ? leafItemIconRender === null || leafItemIconRender === void 0 ? void 0 : leafItemIconRender(menuItem) : null));
        const showSubMenu = (data) => {
            if (isSideBarFold && !isSideBarPreview)
                return false;
            if (sideBarExpandKeys.includes(data.key))
                return true;
            return false;
        };
        const subMenuVisible = showSubMenu({ level, key: menuItem.key });
        return (React.createElement("li", { key: menuItem.key },
            React.createElement("a", { onClick: (e) => {
                    var _a, _b;
                    e.preventDefault();
                    if ((_a = menuItem.children) === null || _a === void 0 ? void 0 : _a.length) {
                        setSideBarExpandKeys((prev) => {
                            if (prev.includes(menuItem.key)) {
                                return prev.filter((item) => item !== menuItem.key);
                            }
                            return [...prev, menuItem.key];
                        });
                    }
                    // 菜单受控
                    if (!((_b = menuItem.children) === null || _b === void 0 ? void 0 : _b.length)) {
                        onSelectMenuItem === null || onSelectMenuItem === void 0 ? void 0 : onSelectMenuItem(menuItem);
                    }
                }, href: menuItem.path, className: classNames({
                    'cn-ui-shell-menu-item': true,
                    'hide-menu-item-icon-fold': isHideMenuItemIconFold,
                    // 是否为展开高亮
                    highlight: isKeyParent(menu, selectedMenuKey, menuItem.key),
                    // 是否为选中高亮
                    actived: selectedMenuKey === menuItem.key ||
                        // 当菜单收起时只显示一级菜单icon，如果这个时候有子菜单被选中的话，需要激活当前一级菜单
                        (isSideBarFold &&
                            !isSideBarPreview &&
                            level === 1 &&
                            isKeyParent(menu, selectedMenuKey, menuItem.key)),
                    activated: selectedMenuKey === menuItem.key ||
                        // 当菜单收起时只显示一级菜单icon，如果这个时候有子菜单被选中的话，需要激活当前一级菜单
                        (isSideBarFold &&
                            !isSideBarPreview &&
                            level === 1 &&
                            isKeyParent(menu, selectedMenuKey, menuItem.key)),
                }) },
                !hideMenuItemIcon && level === 1 ? (React.createElement(CnIcon, { type: menuItem.icon || 'case-fill', className: "shell-menu-text-icon", size: "large" })) : null,
                hideMenuItemIcon && level === 1 ? (React.createElement("span", { className: "menu-text" }, itemText)) : (React.createElement(CnTextEllipsis, { outerClassName: "menu-text", line: level === 1 ? 1 : 2, toolTipProps: { align: 'r' } }, itemText)),
                rightIcon),
            level < 3
                ? renderSubMenu({
                    visible: subMenuVisible,
                    children: menuItem.children || [],
                    level: level + 1,
                })
                : null));
    };
    /**
     * 渲染菜单及子菜单
     */
    const renderSubMenu = ({ children, level, visible, }) => {
        if (!(children === null || children === void 0 ? void 0 : children.length))
            return null;
        if (!visible)
            return null;
        return (React.createElement("div", { className: "cn-ui-shell-sidebar-sub-menu" },
            React.createElement("div", { className: "cn-ui-shell-sidebar-left-line" }),
            React.createElement("div", { className: "cn-ui-shell-sidebar-sub-menu-list" }, children.map((menuItem) => (React.createElement("ul", { key: menuItem.key, className: `level-${level}` }, renderMenuItem(menuItem, level)))))));
    };
    return (React.createElement(motion.aside, { className: classNames('cn-ui-shell-sidebar', {
            fold: isSideBarFold,
            preview: isSideBarPreview,
        }), initial: false, animate: {
            width: !isSideBarFold || isSideBarPreview
                ? SIDEBAR_WIDTH.UNFOLD
                : SIDEBAR_WIDTH.FOLD,
        }, onMouseEnter: handleExpandAside, onMouseLeave: handleFoldAside },
        React.createElement(CnScrollBar, { className: "menu-wrapper" },
            React.createElement(SideBarTopSlot, null),
            React.createElement("ul", { className: "level-1" }, menu.map((item) => renderMenuItem(item, 1)))),
        React.createElement(SideBarFooter, null)));
};
