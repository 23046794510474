import $i18n from 'panda-i18n';
/**
 * 内置文件校验
 */
export const fileValidator = (file) => {
    if (!file) {
        const errorMsg = $i18n.get({
            id: 'InvalidUpload',
            dm: '无效上传',
            ns: 'CnOssUpload',
        });
        throw new Error(errorMsg);
    }
    // 校验文件名长度
    if (file.name.length > 190) {
        const errorMsg = $i18n.get({
            id: 'FileNameIsUpTo_1175914279',
            dm: '文件名最长190个字符',
            ns: 'CnOssUpload',
        });
        throw new Error(errorMsg);
    }
};
export function fileToObject(file, options) {
    return {
        ...options,
        key: options.key,
        percent: options.percent || 0,
        lastModified: file.lastModified,
        name: file.name,
        size: file.size,
        type: file.type,
        originFile: file,
    };
}
