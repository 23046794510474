// @ts-nocheck
/**
 * usage:
 *
 * 1、链式调用 string ｜ object
 * CnMessage.error("错误信息");
 * CnMessage.show({
    type: 'error',
    duration: 200000,
    title: '长度过长',
    content: 'this is content',
    afterClose: () => console.log('Closed the toast'),
  });
 *
 * 2、组件调用
 * <CnMessage title="异常信息" type="error" iconType="exception-manage-fill">
        <Box direction="col" align="center">
          重要提示信息：务必要先check候选人意向再发起操作！
          <CnButton type="primary" size="small">
            面试通过
          </CnButton>
        </Box>
      </CnMessage>
 */
import * as React from 'react';
import Message from './message';
import './index.scss';
// 用户透出信息的上报 fengyue 2022-08-25
const cnMessageShowToUserReport = (props, type) => {
    var _a;
    try {
        // 获取第一个文本的前100字符
        const getFirstText = (rcNode) => {
            if (!React.isValidElement(rcNode)) {
                return `${rcNode}`;
            }
            return getFirstText(React.Children.toArray(rcNode.props.children)[0]).slice(0, 100);
        };
        // 链式调用props?.content
        // 组件调用props?.children
        const hottagName = (typeof props === 'string') ? props.slice(0, 100) : getFirstText((props === null || props === void 0 ? void 0 : props.children) || (props === null || props === void 0 ? void 0 : props.content));
        // console.log('cnMessage/type/hottagName', type, hottagName);
        return (_a = window === null || window === void 0 ? void 0 : window.coneArmsTrace) === null || _a === void 0 ? void 0 : _a.reportHottag({
            name: hottagName,
            eventType: 'showToUser',
            hottag: `cn-ui.cn-message.show-${type}`,
        });
    }
    catch (error) {
        console.log(error);
    }
};
export const CnMessage = (props) => {
    React.useEffect(() => {
        cnMessageShowToUserReport(props, props === null || props === void 0 ? void 0 : props.type);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Message, { "data-name": "CnMessage", ...props })));
};
CnMessage.success = (props) => {
    // cnMessageShowToUserReport(props, 'success');
    if (typeof props === 'string') {
        return Message.success({
            content: props,
        });
    }
    return Message.success({ ...props });
};
CnMessage.warning = (props) => {
    cnMessageShowToUserReport(props, 'warning');
    if (typeof props === 'string') {
        return Message.warning({
            content: props,
        });
    }
    return Message.warning({ ...props });
};
CnMessage.error = (props) => {
    cnMessageShowToUserReport(props, 'error');
    if (typeof props === 'string') {
        return Message.error({
            content: props,
        });
    }
    return Message.error({ ...props });
};
CnMessage.help = (props) => {
    // cnMessageShowToUserReport(props, 'help');
    if (typeof props === 'string') {
        return Message.help({
            content: props,
        });
    }
    return Message.help({ ...props });
};
CnMessage.loading = (props) => {
    // cnMessageShowToUserReport(props, 'loading');
    if (typeof props === 'string') {
        return Message.loading({
            content: props,
        });
    }
    return Message.loading({
        ...props,
    });
};
CnMessage.notice = (props) => {
    // cnMessageShowToUserReport(props, 'notice');
    if (typeof props === 'string') {
        return Message.notice({
            content: props,
        });
    }
    return Message.notice({ ...props });
};
CnMessage.show = (props) => {
    if (typeof props === 'string') {
        return Message.show({
            content: props,
        });
    }
    return Message.show({ ...props });
};
CnMessage.config = (props) => {
    if (typeof props === 'string') {
        return Message.config(props);
    }
    return Message.config({ ...props });
};
CnMessage.destory = () => {
    return Message.destory();
};
CnMessage.hide = () => {
    return Message.hide();
};
CnMessage.displayName = 'CnMessage';
